<template>
  <v-container fluid>
    <v-layout row wrap>
      <Heading :title="$t('forgotPassword.TITLE')" />
      <Description :description="$t('forgotPassword.DESCRIPTION')" />
      <v-flex xs12 sm6 offset-sm3>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <v-layout column>
              <v-flex>
                <ValidationProvider rules="required|email" v-slot="{ errors }">
                  <v-text-field
                    id="email"
                    name="email"
                    type="email"
                    :label="$t('forgotPassword.EMAIL')"
                    v-model="email"
                    :error="errors.length > 0"
                    :error-messages="errors[0]"
                    autocomplete="off"
                  ></v-text-field>
                </ValidationProvider>
              </v-flex>
              <v-flex text-xs-center mt-5>
                <v-btn color="secondary" @click="goBack()" class="mr-3">
                  <v-icon class="mr-2">mdi-arrow-left</v-icon>
                  {{ $t('common.GO_BACK') }}
                </v-btn>
                <SubmitButton :buttonText="$t('forgotPassword.RECOVER')" />
              </v-flex>
            </v-layout>
          </form>
        </ValidationObserver>
      </v-flex>
      <ErrorMessage />
      <SuccessMessage />
    </v-layout>
  </v-container>
</template>

<script>
import router from '@/router'
import { mapActions } from 'vuex'

export default {
  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t('forgotPassword.TITLE')} - %s`
    }
  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    ...mapActions(['forgotPassword']),
    async submit() {
      await this.forgotPassword({
        email: this.email
      })
    },
    goBack() {
      router.go(-1)
    }
  },
  created() {
    if (this.$store.state.auth.isTokenSet) {
      router.push({ name: 'home' })
    }
  }
}
</script>
